import React from "react";
import "./nav.css";
import { BiHomeAlt } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { BsListUl } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { FiMessageSquare } from "react-icons/fi";
import { useState } from "react";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <BiHomeAlt />
        HOME
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <BiUser />
        ABOUT
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <BsListUl />
        SKILLS
      </a>
      <a
        href="#portfolio"
        onClick={() => setActiveNav("#portfolio")}
        className={activeNav === "#portfolio" ? "active" : ""}
      >
        <FiEdit2 />
        PORTFOLIO
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <FiMessageSquare />
        CONTACT
      </a>
    </nav>
  );
};

export default Nav;
