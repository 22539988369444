import React from "react";
import CTA from "./CTA";
import "./header.css";
import ME from "../../assets/profile.png";
import SCROLLICON from "../../assets/scrollicon.png";
import Headersocial from "./Headersocials";

const Header = () => {
  return (
    <section className="header">
      <header className="container header__container">
        <h5 className="text-primary">HEY THERE</h5>
        <h1>I’m Dane Kim.</h1>
        <h5>Fullstack Developer</h5>
        <CTA />
        <Headersocial />

        <div className="me">
          <img src={ME} alt="" />
        </div>

        <a href="#about" className="scroll__down">
          <img src={SCROLLICON} alt="" style={{ width: 20 }} />
        </a>
      </header>
    </section>
  );
};

export default Header;
