import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/doggo.png";
import IMG2 from "../../assets/foodie.png";
import IMG3 from "../../assets/funkyshoes.png";
import IMG4 from "../../assets/catcollector.png";
import IMG5 from "../../assets/ublog.png";
import IMG6 from "../../assets/u2blog.png";

const soloProjects = [
  {
    id: 1,
    title: "Find your doggo",
    img: IMG1,
    link: "https://folksoul91.github.io/SEI_Project-1/",
    github: "https://github.com/folksoul91/SEI_Project-1",
  },
  {
    id: 2,
    title: "Foodie",
    img: IMG2,
    link: "https://tame-goat-bracelet.cyclic.app/",
    github: "https://github.com/folksoul91/foodie",
  },

  {
    id: 3,
    title: "FunkyShoes",
    img: IMG3,
    link: "https://main--dapper-croquembouche-b49505.netlify.app/",
    github: "https://github.com/folksoul91/Project-3-frontend",
  },
  {
    id: 4,
    title: "Catcollector",
    img: IMG4,
    // link: "https://catcollector-avatar-11.herokuapp.com/",
    github: "https://github.com/folksoul91/Cat_Collector",
  },
  {
    id: 5,
    title: "Ublog",
    img: IMG5,
    // link: "https://ublog-11.herokuapp.com/",
    github: "https://github.com/folksoul91/UBlog",
  },
  {
    id: 6,
    title: "U2Blog",
    img: IMG6,
    // link: "https://ublog2app.herokuapp.com/",
    github: "https://github.com/folksoul91/Capstone_U2BLOG",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5 className="port">PORTFOLIO</h5>
      <h2 className="work">Recent Work</h2>
      <div className="container portfolio__container">
        {soloProjects.map(({ id, img, title, github, link }, index) => {
          const isLiveAppVisible = index < 3;

          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={img} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {isLiveAppVisible && (
                  <a
                    id="live-demo"
                    href={link}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live App
                  </a>
                )}
                <a
                  id="github"
                  href={github}
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
