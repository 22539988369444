import React from "react";
import "./about.css";
import ME from "../../assets/profile1.png";
import { BiCode } from "react-icons/bi";
import { FiTrello } from "react-icons/fi";
import { FiGitCommit } from "react-icons/fi";
const about = () => {
  return (
    <section id="about">
      <h5 className="knowme">GET TO KNOW ME</h5>
      <h2 className="allowme">Allow me to introduce myself</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <BiCode className="about__icon" />
              <h5>Development Experience</h5>
              <small>1+ years</small>
            </article>
            <article className="about__card">
              <FiTrello className="about__icon" />
              <h5>Project Mgmt Experience</h5>
              <small>3+ years</small>
            </article>
            <article className="about__card">
              <FiGitCommit className="about__icon" />
              <h5>Coded Projects</h5>
              <small>6+ Completed</small>
            </article>
          </div>
          <p>
            My name is Dane Kim. I’m currently obtaining invaluable experience
            as a full-stack developer and I am looking to expand my skill set
            and gain even more knowledge of the industry. I describe myself as
            dependable, proactive and ambitious. My project management
            background utilizing Agile methodologies has helped me lead and
            organize software development projects, and will make me a valuable
            asset on any software development team.
          </p>
          <a href="#contact" className="btn btn-primary" id="about-btn">
            Let's Chat
          </a>
        </div>
      </div>
    </section>
  );
};

export default about;
