import React from "react";
import { FiLinkedin } from "react-icons/fi";
import { FiGithub } from "react-icons/fi";
// import {FiFacebook} from 'react-icons/fi'

const Headersocials = () => {
  return (
    <div className="header__socials">
      <a href="https://linkedin.com" target="_blank" rel="noreferrer">
        <FiLinkedin />
      </a>
      <a href="https://github.com" target="_blank" rel="noreferrer">
        <FiGithub />
      </a>
      {/* <a href="https://facebook.com" target="_blank"><FiFacebook/></a> */}
    </div>
  );
};

export default Headersocials;
