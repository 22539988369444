import React from "react";
import "./experience.css";
import { BsCheck2Square } from "react-icons/bs";

const Experience = () => {
  return (
    <section id="experience">
      <h5 className="technical">LET'S GET TECHNICAL</h5>
      <h2 className="skills">Skills & Experience</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>JQuery</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>React</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Node JS</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Express</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>PostgreSQL</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Mongoose</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Python</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsCheck2Square className="experience__details-icon" />
              <div>
                <h4>Django</h4>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
