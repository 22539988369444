import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { RiMessengerLine } from "react-icons/ri";
import { useRef } from "react";
import emailjs from "emailjs-com";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.React_App_Service_ID,
      process.env.React_App_Email_Template,
      form.current,
      process.env.React_App_Public_Key
    );

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5 className="chat">LET'S CHAT</h5>
      <h2 className="contact">Contact</h2>
      <h5 className="reach">
        Reach out using the form below or email me at dkdeveloper91@gmail.com
      </h5>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>dkdeveloper91@gmail.com</h5>
            <a
              href="mailto:dkdeveloper91@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Let's Chat
            </a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>Dane Kim</h5>
            <a href="https://m.me/dane.kim.5" target="_blank" rel="noreferrer">
              Let's Chat
            </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Type Your Message Here"
            required
          ></textarea>
          <button id="contact-btn" type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
