import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { Pagination, Autoplay, Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./testimonials.css";

const testimonials = [
  {
    id: 1,
    link: "https://www.linkedin.com/in/cameron-callery-48279173/",
    name: "Cameron Callery",
    role: "Fullstack Developer | Scala",
    test: "Dane is a hardworking driven individual. With the limited time we've worked together I've noticed a significant improvement in his technical abilities. He challenges himself, and is dedicated to growing and improving his skills as a software developer.",
  },
  {
    id: 2,
    link: "https://www.linkedin.com/in/iyana-marquez/",
    name: "Iyana Marquez",
    role: "Web Developer | General Assembly",
    test: "Dane is a highly skilled and hardworking programmer. I had the pleasure of working closely with Dane on an eCommerce project and he was a key part of our success. His leadership and development skills are an invaluable asset to any team who is fortunate enough to work with him.",
  },
  {
    id: 3,
    link: "https://www.linkedin.com/in/matthew-pero22/",
    name: "Matthew Pero",
    role: "Full Stack Software Engineer, General Aseembly",
    test: "Dane is an incredible teammate and programmer that I was fortunate enough to meet through GA. On multiple occasions, I found Dane's insight into a problem extremely helpful and communicated with precision. I had a pleasure working through classwork and homework with him and would definitely enjoy coding with him again!",
  },
  {
    id: 4,
    link: "https://www.linkedin.com/in/mallory-kim-43779497/",
    name: "Mallory Kim",
    role: "Principal Product Designer, Dell",
    test: "Dane is the fastest learner I've ever known. When he loves to do something, he gives it his all and never gives up or gets discouraged. He has overcame many things in his career and continues to move up. I'm excited to see where Dane goes next.",
  },
  {
    id: 5,
    link: "https://www.linkedin.com/in/jonmichaeluy/",
    name: "Jon Michael Uy",
    role: "Software Engineer, General Assembly",
    test: "During our time at General Assembly, Dane created highly functional and well-styled web applications that really showed his technical abilities. He also has a great eye for troubleshooting and fixing broken code. He was very helpful and great to work with.",
  },
  {
    id: 6,
    link: "https://www.linkedin.com/in/marvin-merida/",
    name: "Marvin Merida",
    role: "Software Engineer, General Assembly",
    test: "Dane is a great guy to work with. He has an eye for clean aesthetics and design. I was really impressed with his work.",
  },
];
const Testimonials = () => {
  return (
    <section id="testmonials">
      <h5 className="review">WHAT OTHERS SAY ABOUT ME</h5>
      <h2 className="test">Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{ delay: 4000, disableOnInteraction: false }}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={true}
      >
        {testimonials.map((test) => (
          <SwiperSlide className="testimonial" key={test.id}>
            <div className="client__avatar">
              <a href={test.link}>
                <BsLinkedin />
              </a>
            </div>
            <h5 className="client__name">{test.name}</h5>
            <small className="client__review">{test.test}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
